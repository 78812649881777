import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import DrawerAppBar from './Header'


function Home() {
  
  return (
    <React.Fragment>
      <DrawerAppBar />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
}


export default Home;