import React from 'react';
import { Grid, Typography, useMediaQuery} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Image2 from '../../ui/principal.jpg';


const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
    marginBottom: theme.spacing(4),
    
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(20),
  },
  subheading: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  paragraph: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  iconContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop:"1em",
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop:"1em",
      marginBottom: theme.spacing(2),
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '100%',
    height: 'auto',
    maxWidth: '300px',
  },
}));

const PrincipalMessage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesSMU = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>


        <Typography variant="h2" className={classes.header} style={{ paddingBottom:  matchesSM?".1em":'.7em' }} sx={{
            fontSize: {
                xs: "1.7rem",
                sm: "2rem",
                md: "3rem",
                xl: "3rem"
              },
              fontWeight: 800,
          }}>
          Chief PaperMaker's Message
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>

        <Typography variant="subtitle1" className={classes.subheading} sx={{ 
              fontSize: {
                xs: "1rem",
                sm: "1.5rem",
                md: "1.6rem",
                xl: "2rem"
              },
              fontWeight: 'bold',
            }}>
            Dear valued audience,
        </Typography>

        <Typography variant="subtitle1" className={classes.paragraph} sx={{ 
              fontSize: {
                xs: "1rem",
                sm: "1.2rem",
                md: "1.3rem",
                xl: "1.4rem"
              },
            }}>
      

         
I feel greatly humbled and honored to share our passion for paper and excellence with each one of you.
Our journey in the art of papermaking has been one of dedication, innovation, and a commitment to excellence.

</Typography>
<Typography variant="subtitle1" className={classes.paragraph} sx={{ 
              fontSize: {
                xs: "1rem",
                sm: "1.2rem",
                md: "1.3rem",
                xl: "1.4rem"
              },
            }}>
              <br></br>
I am proud to lead a team dedicated to crafting excellence in every sheet.
Our commitment to quality, sustainability, and innovation drives us to create paper that
not only meets your needs but exceeds your expectations. With every fiber woven,
we aim to inspire your creativity and empower your ideas. Together, 
let's make a mark on the world with our exceptional paper products.
Thank you for choosing us as your trusted paper partner."




        </Typography>

        <Typography variant="subtitle1" className={classes.subheading} sx={{ 
              fontSize: {
                xs: "1rem",
                sm: "1.5rem",
                md: "1.6rem",
                xl: "2rem"
              },
              fontWeight: 'bold',
            }}>
          Warm regards
        </Typography>

        <Typography variant="h3" className={classes.subheading} sx={{ 
              fontSize: {
                xs: ".8rem",
                sm: "1.5rem",
                md: "1.6rem",
                xl: "2rem"
              },
              fontWeight: 'bold',
            }}>
         MR Rakesh
        </Typography>

        <Typography variant="subtitle1" className={classes.subheading} sx={{ 
              fontSize: {
                xs: "1rem",
                sm: "1.5rem",
                md: "1.6rem",
                xl: "2rem"
              },
              fontWeight: 'bold',
            }}>
         Chief PaperMaker RK Papers
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.iconContainer}>
        <img className={classes.icon} alt="principal" src={Image2} />
      </Grid>
    </Grid>
  );
};

export default PrincipalMessage;
