import React from 'react';
import { Grid, Typography, Button, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PrincipalMessage from './components/Pmessage'
import SchoolEvents from './components/SchoolEvents'
import AdministrationGrid from './components/AdministrationGrid'
import HomePage from './La'

import backg from '../ui/10.jpeg';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  video: {
    filter: "brightness(60%)",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  contentContainer: {
    position: 'relative',
    zIndex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  logo: {
    width: '15%',
    height: 'auto',

    marginBottom: theme.spacing(1),

    [theme.breakpoints.between(900, 1285)]:{
     width: '20%',
    },

    [theme.breakpoints.down('md')]: {
      width: '30%',
    },

    [theme.breakpoints.down('sm')]: {
      width: '35%',
    },
  },
  button: {
    borderRadius: theme.shape.borderRadius,
    color: '#fff',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '160px',
      fontSize: '0.9rem',
    },
    [theme.breakpoints.up('sm')]: {
      width: '240px',
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  gridItem: {
    textAlign: 'center',
  },
}));

const VideoGrid = () => {
  const classes = useStyles();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (

    <Grid>
      <Grid item xs={12}>
        <HomePage/>
      </Grid>

      <Grid item xs={12}>
        <PrincipalMessage/>
      </Grid>
          
      <Grid item xs={12}>
        <SchoolEvents/>
      </Grid>
          
      <Grid item xs={12}>
        <AdministrationGrid/>
      </Grid>


    </Grid>
  );
};

export default VideoGrid;
