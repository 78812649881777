import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../ui/paper.png';
import logo1 from '../ui/copy.png';

import { makeStyles } from '@mui/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Tab from '@mui/material/Tab';

import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';


import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

const drawerWidth = 150;

const navItems = [
    { name: "Home", link: "/", activeIndex: 0 },
    { name: "Products", link: "/services",activeIndex: 1 },
    { name: "Office Supplies", link: "/booking", activeIndex: 2 },
    { name: "About Us", link: "/about", activeIndex: 3 },
  ];

const settings = [
    { name: "Profile", link: "/user/profile", activeIndex: 0 },
    { name: "Admin Pannel", link: "/admin", activeIndex: 2 },
    { name: "New Bookings", link: "/admin/new-booking",activeIndex: 1 },
    
  ];

const user_settings = [
    { name: "Profile", link: "/user/profile", activeIndex: 0 },
    { name: "Booking", link: "/user/booking", activeIndex: 2 },
  ];

const useStyles = makeStyles(theme => ({
  logo: {
    height: "5em",
    paddingLeft:"1em",
    [theme.breakpoints.down("md")]: {
      height: "4em"    
    },
    [theme.breakpoints.down("xs")]: {
      height: "3.5em"
    }
  },
  logo1: {
    height: "5em",
    paddingLeft:"1.4em",
    [theme.breakpoints.down("md")]: {
      height: "4em"    
    },
    [theme.breakpoints.down("xs")]: {
      height: "3.5em"
    }
  },
  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  tab: {
    minWidth: 20,
    '&:hover': {
    color: '#2779c2',
    opacity: 1,
    },

  },
  tabBook: {
    minWidth: 20,
    marginLeft: "45px",

    '&:hover': {
    color: '#004D49',
    opacity: 1,
  },
  },
  tabContainer: {
    marginLeft: "auto"
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  drawerIcon: {
    height: "50px",
    width: "50px"
  },
  button: {
    marginLeft: "20px",
    marginRight: "10px",
    [theme.breakpoints.down("md")]: {
       marginLeft: "auto" 
     
    },
    [theme.breakpoints.down("xs")]: {
       marginLeft: "auto"
    },
  signbu: {
    [theme.breakpoints.down("sm")]: {
      height: 20,
      width: 30,
    },
    [theme.breakpoints.down("md")]: {
      height: 20,
      width: 30,
    },
  }
  },
  }));


const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: 800,
    fontSize: 18,
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#2d3b8d',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#2779c2',
    },
  }),
);



function DrawerAppBar(props) {
  const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [onHover, setOnHover] = React.useState(false);
  
  // const [trigger, setTrigger] = React.useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();


  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const trigger = useScrollTrigger({disableHysteresis: true, threshold: 150});


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handletabValue = (e, newValue) => {
    setTabValue(newValue);
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const handleOnHover = (index) => {
    setOnHover(true);
  };

  const handleOnLeave = (index) => {
    
    if (trigger === true) {
     setOnHover(true) 
    } else if(location.pathname !== "/"){
      setOnHover(true);
    }else{
      setOnHover(false);
    }

  };

  useEffect(() => {
    if (location.pathname !== "/"){
      setOnHover(true);
    }else{
      if (trigger === true) {
       setOnHover(true);
      } else {
        setOnHover(false);
      } 
    }


  }, [trigger, location.pathname]);



  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Button
              component={Link}
              to="/"
              disableRipple
              sx={{display: { xs: 'block', sm: 'block' } }}
              className={classes.logoContainer}
              
            >
              <img alt="company logo" className={classes.logo} src={logo} />
            </Button>
      <Divider />
      <List>
        {navItems.map((page, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton component={Link} to={page.link} sx={{ textAlign: 'center' }}>
              <ListItemText color="main" primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed"  component="nav" 
        onMouseOver={() => handleOnHover()}
        onMouseLeave={() => handleOnLeave()}
        elevation={onHover ? 1 : 0}
        style={{
          backgroundColor: onHover ? "#FFFFFF" : "transparent",
          boxShadow: onHover
            ? "5px 0px 27px -5px rgba(0, 0, 0, 0.3) !important"
            : undefined
        }}>


        <Toolbar>
          
          <Button
              component={Link}
              to="/"
              size="small"
              disableRipple
              
              className={classes.logoContainer}
            >
              <img alt="company logo" className={onHover ? classes.logo1 : classes.logo} src={onHover ? logo1 : logo} />
            </Button>

          <StyledTabs
            sx={{display: { xs: 'none', md: 'flex' } }}
            value={tabValue}
            onChange={handletabValue}
            className={classes.tabContainer}
            center="true"
            textColor="white"
          >

            <StyledTab
                sx={{ color: onHover ? "#05aced" : "white" }}
                className={classes.tab}
                component={Link}
                to='/'
                label="Home"
              />
            <Divider sx={{ borderRightWidth: 2.5 }} orientation="vertical" variant="middle" flexItem />
            <StyledTab
                sx={{ color: onHover ? "#05aced" : "white" }}
                className={classes.tab}
                component={Link}
                to="/products"
                label="Products"
              />
            

            <Divider sx={{ borderRightWidth: 2.5 }} orientation="vertical" variant="middle" flexItem />
            
          </StyledTabs>
          

          <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center" className={classes.button}>
                <Button variant="contained"
                 

                  sx = {{ height: matchesMD ? 30:35,
                        width: matchesMD ? 10:150,
                        borderRadius: matchesMD ? 20:28,
                        fontSize: matchesMD ? "0.45rem":"0.8rem",
                        textAlign: 'center'}}

                  color="secondary" component={Link} to='/contactus' >Contact Us</Button>
              </Stack>

          <IconButton
            color="primary"
            className={classes.drawerIconContainer}
            onClick={handleDrawerToggle}
            disableRipple
            sx={{mr: 2, display: { md: 'none' }}}
          >
            <MenuIcon className={classes.drawerIcon} />
          </IconButton>




        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor='right'
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md:'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;