import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Transition } from 'react-transition-group';
import bg1 from '../ui/Prime.jfif';

import art1 from '../ui/Eco.jfif';
import bus from '../ui/Prime.jfif';
import com from '../ui/Eco.jfif';
import lab from '../ui/Prime.jfif';
import lib from '../ui/Eco.jfif';
import spo from '../ui/Prime.jfif';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // height: '80vh',
    marginTop: theme.spacing(7),
    // [theme.breakpoints.down('md')]: {
    //   height: '60vh',
    // },
  },
  gridItem:{
    marginTop: theme.spacing(7),
    padding: theme.spacing(2),
    maxWidth: 1300,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  header: {
    position: 'relative',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    // filter: "brightness(60%)",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bg1})`,
    // backgroundImage: `url(${bg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  facilityContainer: {
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#05aced",
    },
    transition: 'background-color 0.3s ease-in-out',
  },
  selectedFacilityContainer: {
    backgroundColor: 'red',
    color: theme.palette.common.white,
  },
  facilityText: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  facilityDetailsContainer: {
    // padding: theme.spacing(1),
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(0),
    },
  },
  facilityImage: {
    width: '100%',
    // height: 'auto',
    height: '350px',
    marginBottom: theme.spacing(2),
  },
  facilityDescription: {
    color: theme.palette.text.primary,
  },
}));

const Products = () => {
  const classes = useStyles();
  const [selectedFacility, setSelectedFacility] = useState('Premium White Pearl');

  const handleFacilityClick = (facilityName) => {
    setSelectedFacility(facilityName);
  };

  const facilityList = [
    { name: 'Premium White Pearl', 
      description: "A high-quality, smooth, and bright white paper rim, perfect for professional documents and presentations.", 
      image: lib },
    { name: 'Natural Ivory Elegance', 
      description: "An elegant and refined ivory-colored paper rim, ideal for sophisticated invitations and formal correspondence.", 
      image: lab },
    { name: 'Recycled Eco-Chic', 
      description: "An eco-friendly paper rim made from recycled fibers, offering a sustainable choice for environmentally conscious users.", 
      image: com },
    { name: 'Luxe Linen Finery', 
      description: "luxurious linen-like texture and high-quality finish. This type of paper is often used for upscale invitations, stationery, business cards, and other printed materials where a sophisticated appearance is desired.", 
      image: spo },
    { name: 'Colorful Expression', 
      description: "A vibrant assortment of colored paper rims, perfect for crafting, arts, and creative projects.", 
      image: art1 },
    { name: 'Executive Bond', 
      description: "A smooth, heavyweight paper rim suitable for business reports, resumes, and official documents.", 
      image: bus },
  ];

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Box className={classes.header}>
          <Typography variant="h2" sx={{
            fontSize: {
                xs: "2rem",
                sm: "2.3rem",
                md: "3.5rem",
                xl: "3.5rem"
              },
              fontWeight: 800,
              color:"white",
          }}>
            Products
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} >
        <Grid container className={classes.gridItem}>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            {facilityList.map((facility) => (
              <Transition key={facility.name} in={selectedFacility === facility.name} timeout={300}>
                {(state) => (
                  <Paper
                    className={classes.facilityContainer}
                    sx={{
                      backgroundColor: selectedFacility === facility.name ? '#05aced' : 'initial',
                      color: selectedFacility === facility.name ? '#A0DFF8' : 'initial',
                    }}
                    onClick={() => handleFacilityClick(facility.name)}
                  >
                    <Typography variant="h6" className={classes.facilityText}>
                      {facility.name}
                    </Typography>
                  </Paper>
                )}
              </Transition>
            ))}
          </Grid>
          <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
            <Box className={classes.facilityDetailsContainer}>
              {facilityList.map((facility) => (
                <Collapse key={facility.name} in={selectedFacility === facility.name} timeout={300}>
                  <div>
                    <img src={facility.image} alt={facility.name} className={classes.facilityImage} />
                    <Typography variant="h6" className={classes.facilityText}>
                      {facility.name}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.paragraph} sx={{ 
              fontSize: {
                xs: "1rem",
                sm: "1.2rem",
                md: "1.3rem",
                xl: "1.4rem"
              },
            }}>
                    
                      {facility.description}
                    </Typography>
                  </div>
                </Collapse>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Products;
