import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, IconButton, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import backgroundImage1 from '../ui/10.jpeg';
import backgroundImage2 from '../ui/4.jpeg';
import backgroundImage3 from '../ui/5.jpeg';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: 'background-image 0.5s ease-in-out',
  },
  contentContainer: {
    position: 'relative',
    zIndex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    maxWidth: '50%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
  },
  rightButtonContainer: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  leftButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  arrowButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    color: 'red',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  prevButton: {
    left: 0,
  },
  nextButton: {
    right: 0,
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const [backgroundImages, setBackgroundImages] = useState([
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
  ]);
  const theme = useTheme();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isXsScreen = useMediaQuery(theme.breakpoints.down('md'));

  const slides = [
    {
      title: 'RK Papers',
      content:
        'Unfolding Ideas, Crafting Excellence: Your Trusted Paper Partner. ',
    },
    {
      title: 'Paper Story',
      content:
        'From fibers woven, our paper is born, A canvas for dreams, where ideas adorn. Crafted with care, a symphony of art,Trust in our sheets, the perfect start.',
    },
    {
      title: 'Office Paper',
      content:
        'Empowering offices with precision and grace, paper delivers a professional embrace. With quality that sets your work apart,  Choose us for a seamless start.',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % backgroundImages.length
      );
    }, 10000);

    return () => clearInterval(interval);
  }, [backgroundImages]);

  const goToPreviousSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? backgroundImages.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex + 1) % backgroundImages.length
    );
  };

  return (
    <Grid
      container
      className={classes.gridContainer}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${backgroundImages[currentImageIndex]})`,
      }}
    >
    
      <div className={classes.leftButtonContainer}>
        <IconButton
          className={classes.arrowButton}
          onClick={goToPreviousSlide}
        >
          <ChevronLeftIcon style={{ color: 'white', fontSize: isXsScreen?"35px":"80px" }} />
        </IconButton>
      </div>
      
      <div className={classes.rightButtonContainer}>
        <IconButton className={classes.arrowButton} onClick={goToNextSlide}>
          <ChevronRightIcon style={{ color: 'white', fontSize: isXsScreen?"35px":"80px" }} />
        </IconButton>
      </div>

      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <Box>
          <Typography variant="h1" sx={{ 
              fontSize: {
                xs: "2.5rem",
                sm: "3rem",
                md: "4rem",
                xl: "6rem"
              },
              color: "#FFFFFF" }}>
            {slides[currentImageIndex].title}
          </Typography>
          <Typography variant="subtitle1" sx={{ 
              fontSize: {
                xs: ".8rem",
                sm: "1.2rem",
                md: "1.5rem",
                xl: "2rem"
              },
              fontWeight: 'bold',
              color:"white",
            }}>
            {slides[currentImageIndex].content}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomePage;
