import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';
import { Card, CardActionArea, CardContent, CardMedia, Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Inagurationday from '../../ui/Inaguration.jpeg';
import Production from '../../ui/Production.jfif';
import FirstOrder from '../../ui/FirstOrder.jfif';
import Cusotmer1 from '../../ui/100Customer.gif';
import Order from '../../ui/10000Order.jpg';
import Cusotmer2 from '../../ui/1000Customer.jfif';


const useStyles = makeStyles((theme) => ({
  container: {
    background: ``,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  header: {
    textAlign: 'center',
    fontSize: '2.6rem',
    letterSpacing: '.3rem',
    marginBottom: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
    },
  },
  timelineItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  timelineOppositeContent: {
    [theme.breakpoints.up('md')]: {
      flex: 0.56,
    },
  },

  card: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },

  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.between(500, 600)]:{
     margin: theme.spacing(6),
    },
  },
  paper: {
    textAlign: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Add box shadow effect
  },

}));

const SchoolEvents = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
      <Typography variant="h2" component="h1" className={classes.header} style={{ paddingBottom:  matchesSM?".1em":'.7em' }}>
         Our Journey
        </Typography>
       
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineOppositeContent className={classes.timelineOppositeContent}>
              <Typography variant="subtitle2">
                1 December 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="subtitle1">
                <strong>Inaguration of RK Papers Enterprises</strong>
              </Typography>
            </TimelineContent>
          </TimelineItem>

          
          <TimelineItem>
            <TimelineOppositeContent className={classes.timelineOppositeContent}>
              <Typography variant="subtitle2">
                10 December 2022
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="subtitle1">
                <strong>First Paper Production</strong>
              </Typography>
            </TimelineContent>
          </TimelineItem>

          
          <TimelineItem>
            <TimelineOppositeContent className={classes.timelineOppositeContent}>
              <Typography variant="subtitle2">
              12 December
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="subtitle1">
                <strong>First Customer Order completion</strong>
              </Typography>
            </TimelineContent>
          </TimelineItem>

          
          <TimelineItem>
            <TimelineOppositeContent className={classes.timelineOppositeContent}>
              <Typography variant="subtitle2">
                22 February
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="subtitle1">
                <strong>100 Cutomer Milestone</strong>
              </Typography>
            </TimelineContent>
          </TimelineItem>

          
          <TimelineItem>
            <TimelineOppositeContent className={classes.timelineOppositeContent}>
              <Typography variant="subtitle2">
                1 April
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="subtitle1">
                <strong>10000 Order Milestone</strong>
              </Typography>
            </TimelineContent>
          </TimelineItem>
         
          <TimelineItem>
            <TimelineOppositeContent className={classes.timelineOppositeContent}>
              <Typography variant="subtitle2">
                23  July
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="subtitle1">
                <strong>1000 Cutomer Milestone</strong>
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Grid>

      <Grid item xs={12} sm={12} md={8} style={{marginBottom: matchesSM?theme.spacing(5) : theme.spacing(8)  }}>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} className={classes.paper}>
              <CardActionArea disableGutters>
                <CardMedia component="img" height="140" image={Inagurationday} alt="Innaguration Ceremony" />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Innaguration Ceremony
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                   Unveiling our paper manufacturing journey.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} className={classes.paper}>
              <CardActionArea disableGutters>
                <CardMedia component="img" height="140" image={Production} alt="Production start Day" />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    First Production
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  Let the production begin - a new era dawns!.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} className={classes.paper}>
              <CardActionArea disableGutters>
                <CardMedia component="img" height="140" image={FirstOrder} alt="First Order Celebration" />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    First Order Rollout
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  our first-order roll-out: Experience the future now!
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} className={classes.paper}>
              <CardActionArea disableGutters>
                <CardMedia component="img" height="140" image={Cusotmer1} alt="100th Customer Celebration" />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    100 Customer Milestome
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  Grateful for reaching our 100th customer, and committed to serving a hundredfold more with excellence and dedication.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} className={classes.paper} >
              <CardActionArea disableGutters>
                <CardMedia component="img" height="140" image={Order} alt="10000th Order Celebration" />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    10000 orders Milestome
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  Thrilled to reach our 10000th order, a testament to our commitment to excellence and customer satisfaction!
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} className={classes.paper} >
              <CardActionArea disableGutters>
                <CardMedia component="img" height="140" image={Cusotmer2} alt="1000th Cusotmer celebration" />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                  1000 Cusotomer Milestome
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  Thank you for being one in a thousand – our valued customers who make our journey extraordinary!
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Paper>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
};

export default SchoolEvents;
