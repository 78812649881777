import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ThemeProvider } from '@mui/material/styles';
import theme from './components/ui/Theme';

import Home from './components/main/Home';

import VideoGrid from './components/main/LandingPage';
import ContactUs from './components/main/ContactUs';
import Products from './components/main/Products';

import Error from './components/Error';



function App() {


  return (
    <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<VideoGrid />} />

            <Route path="/contactus" element={<ContactUs />}/>
            <Route path="/products" element={<Products />}/>
            
          </Route>
        </Routes>
    </ThemeProvider>
  );
}

export default App;