import React from 'react';
import { Grid, Box, Typography, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ContactUsForm from './components/ContactUsForm'

import { useTheme } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Avatar from '@mui/material/Avatar';
import bg1 from '../ui/paper.png';


const useStyles = makeStyles((theme) => ({
  container1: {
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  paper: {
    padding: theme.spacing(2),
  },
  header: {
    position: 'relative',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    // filter: "brightness(60%)",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bg1})`,
    // backgroundImage: `url(${bg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const ContactPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
  };

  return (
    <Grid container spacing={0} sx={{marginTop:"4em", maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto'}}>

      <Grid item xs={12}>
        <Box className={classes.header}>
          <Typography variant="h2" sx={{
            fontSize: {
                xs: "2rem",
                sm: "2.3rem",
                md: "3.5rem",
                xl: "3.5rem"
              },
              fontWeight: 800,
              color:"white",
          }}>
            Contact Us
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4} className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}> 
            <Typography component="h1" variant="h5" textAlign="center">
              Organization information
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Avatar className={classes.avatar}>
              <LocationOnIcon />
            </Avatar>
          </Grid>

          <Grid item xs={10}>
            <Typography variant="subtitle1" sx={{fontSize:{
                xs: "1rem",
                sm: "1rem",
                md:".8em",
                xl: ".8rem" }}}>
              <strong>Bahadurpur, Atakfarm, Selaqui, Dehradun, Uttarakhand,248197</strong>
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Avatar className={classes.avatar}>
              <PhoneIcon />
            </Avatar>
          </Grid>
           <Grid item xs={10}>
             <Typography variant="subtitle1" sx={{fontSize:{
                xs: "1rem",
                sm: "1rem",
                md:".8em",
                xl: ".8rem" }}}>
              <strong> +91 9837214191</strong>
            </Typography>
            <Typography variant="subtitle1" sx={{fontSize:{
                xs: "1rem",
                sm: "1rem",
                md:".8em",
                xl: ".8rem" }}}>
              <strong> +91 7455061544</strong>
            </Typography>
            
          </Grid>

          <Grid item xs={2}>
            <Avatar className={classes.avatar}>
              <EmailIcon />
            </Avatar>
          </Grid>
           <Grid item xs={10}>
            <Typography variant="subtitle1" sx={{fontSize:{
                xs: "1rem",
                sm: "1rem",
                md:".8em",
                xl: ".8rem" }}}>
              <strong> RkPapersEnterprises@gmail.com</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={8} className={classes.paper}>
        <ContactUsForm/>
      </Grid>
      
    </Grid>
  );
};

export default ContactPage;
