import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import a10 from '../../ui/20.png';
import a13 from '../../ui/21.png';
import a14 from '../../ui/22.png';
import a15 from '../../ui/23.png';


const useStyles = makeStyles((theme) => ({
  serviceContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    textAlign: 'center',
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const AdministrationGrid = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12} textAlign="center" alignItems="center" sx={{ paddingTop: "2em" }}>
        <Typography variant="h2" component="a" textAlign="center" sx={{ fontSize: "2.6rem", letterSpacing: '.3rem' }}>
          Our Team
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction="column"
        justify="center"
        className={classes.serviceContainer}
        xs={12} sm={6} md={3}
      >
        <Grid item>
          <img alt="loss diet" height="140" src={a14} />
        </Grid>
        <Grid item>
          <Typography variant="h4"></Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
          Plant Manager
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="column"
        justify="center"
        className={classes.serviceContainer}
        xs={12} sm={6} md={3}
      >
        <Grid item>
          <img alt="diet Management" height="140" src={a13} />
        </Grid>
        <Grid item>
          <Typography variant="h4"></Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
          Production Supervisor
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="column"
        justify="center"
        className={classes.serviceContainer}
        xs={12} sm={6} md={3}
      >
        <Grid item>
          <img alt="custom software icon" height="140" src={a10} />
        </Grid>
        <Grid item>
          <Typography variant="h4"></Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
          Machine Operators
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="column"
        justify="center"
        className={classes.serviceContainer}
        xs={12} sm={6} md={3}
      >
        <Grid item>
          <img alt="custom software icon" height="140" src={a10} />
        </Grid>
        <Grid item>
          <Typography variant="h4"></Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
          Administrative Staff
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="column"
        justify="center"
        className={classes.serviceContainer}
        xs={12} sm={6} md={3}
      >
        <Grid item>
          <img alt="custom software icon" height="140" src={a10} />
        </Grid>
        <Grid item>
          <Typography variant="h4"></Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
          Logistics and Warehouse Personnel
          </Typography>
        </Grid>
      </Grid>


      <Grid
        item
        container
        direction="column"
        justify="center"
        className={classes.serviceContainer}
        xs={12} sm={6} md={3}
      >
        <Grid item>
          <img alt="custom software icon" height="140" src={a15} />
        </Grid>
        <Grid item>
          <Typography variant="h4"></Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
          Quality Control Technician
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdministrationGrid;
