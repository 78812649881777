import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CopyrightIcon from '@mui/icons-material/Copyright';

// import logo1 from '../ui/clogo.png';
import logo1 from '../ui/copy.png';
import backgroundImg from '../ui/backg1.png';


const useStyles = makeStyles((theme) => ({
  footer: {
    background: `url(${backgroundImg}) center center/cover no-repeat`,
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  logoContainer: {
    display: 'block',
  },
  logo: {
    width: '50%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '25%',
    },
  },
  link: {
    textAlign: 'center',
    '& h4': {
      margin: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6rem',
      },
    },
  },
  socialIcon: {
    margin: theme.spacing(1),
  },
  copyright: {
    marginLeft: theme.spacing(1),
    fontSize: '0.8rem',
    color: "#2d3b8d",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={6} sm={3}>
          <Button
            disableRipple
            className={classes.logoContainer}
          >
            <img alt="company logo" className={classes.logo} src={logo1} />
          </Button>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 1, sm: 3, md: 5, xl: 7 }}
          >
            <Grid item className={classes.link}>
              <Typography variant="h6">
                Products
              </Typography>
            </Grid>
            <Grid item className={classes.link}>
              <Typography variant="h6">
               
               Contact Us
              </Typography>

            </Grid>
            <Grid item className={classes.link}>
              <Typography variant="h6">
                About Us
                
        
              </Typography>

            </Grid>
            <Grid item className={classes.link}>
              <Typography variant="h6">
                Messages
              </Typography>

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item className={classes.socialIcon}>
            <a href="https://www.facebook.com/RkPapers2022/" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
              </a>
            </Grid>
            <Grid item className={classes.socialIcon}>
            <a href="https://twitter.com/RkPapers" target="_blank" rel="noopener noreferrer">
              <TwitterIcon /></a>
            </Grid>
            <Grid item className={classes.socialIcon}>
            <a href="https://www.instagram.com/rkpapersenterprises/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon /></a>
            </Grid>
            
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <span>
                <CopyrightIcon color="disabled" sx={{ fontSize: 15 }} />
              </span>
            </Grid>
            <Grid item className={classes.copyright}>
              @Rk Papers Enterprises. All rights reserved.
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
